import React, {useEffect, useState} from 'react';
import config from '../../config/main.config';
import {withTranslation} from 'react-i18next';
import EditHeaderComponent from "../../components/editHeader/EditHeaderComponent";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import MapContainer from "../../components/map/Map";
import EditTextComponent from "../../components/editText/EditTextComponent";
import sharedConfig from "../../../shared/sharedConfig";

const namespace = 'contact'

const Contact = (props) => {
    const {t} = props;
    const [sendSuccessful, setSendSuccessful] = useState(false);
    const [isCallback, setIsCallback] = useState(false);
    return (
        <div className={'grid ui'} style={{paddingTop: '50px', maxWidth: "1248px", margin: "auto"}}>
            <div style={{display: 'none'}}>
                <BreadCrumbs breadCrumbData={[{title: 'Kontakt', url: '/contact'}]}/>
            </div>
            <div className={'row centered'}>
                <div className={"fourteen wide column"}>
                    <div className={"contact-header"}>
                        <EditHeaderComponent namespace={namespace} id={`${namespace}-header`}/>
                    </div>
                    <EditTextComponent namespace id={`${namespace}-text-1`}/>
                </div>
            </div>
            <div className={"row centered"}>
                <div className={"fourteen wide column"}>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        let formData = new FormData(e.currentTarget);
                        const data = {}
                        for (let [key, value] of formData.entries()) {
                            data[key] = value
                        }
                        fetch(`${config.BASE_URL}contact/`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(data),
                        }).then(_ => {setSendSuccessful(true); e.currentTarget.reset()})
                    }}
                          className={"contact-grid cols-2"}>
                        <section>
                            <div className={"select-dropdown"}>
                                <label htmlFor={"room"}>{t("contact:contact.select_room")}</label>
                                <select name={"room"} id={"room"}>
                                    <option value={"select"} selected disabled hidden></option>
                                    {sharedConfig.rooms.map(room => <option value={room} key={room}>{room}</option>)}
                                </select>
                            </div>
                        </section>
                        <section/>
                        <section className={"flex"}>
                            <div style={{width: "100%"}}>
                                <label htmlFor={"dateFrom"}>{t("contact:contact.date_from")}</label>
                                <input name={"dateFrom"} id={"dateFrom"} type={"date"}
                                       min={new Date().toISOString().split('T')[0]}/>
                            </div>
                            <div style={{width: "100%"}}>
                                <label htmlFor={"dateUntil"}>{t("contact:contact.date_until")}</label>
                                <input name={"dateUntil"} id={"dateUntil"} type={"date"}/>
                            </div>
                        </section>
                        <section className={"flex mobile-grid"}>
                            <div>
                                <label htmlFor={"adults"}>{t("contact:contact.adults")}</label>
                                <span className="input-wrapper">
                                    <button id="decrement-adult" type={"button"}
                                            onClick={() => document.getElementById("adults").value = document.getElementById("adults").value > 0 ? parseInt(document.getElementById("adults").value) - 1 : 0}><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24"
                                        viewBox="0 0 24 24" fill="none"
                                        stroke="var(--secondary_color)"
                                        strokeWidth="1"

                                        className="icon icon-tabler icons-tabler-outline icon-tabler-minus"><path
                                        stroke="none" d="M0 0h24v24H0z" fill="none"/><path
                                        d="M5 12l14 0"/></svg></button>
                                    <input type="number" value={0} min={0} id="adults" name={"adults"}/>
                                    <button id="increment-adult" type={"button"}
                                            onClick={() => document.getElementById("adults").value = parseInt(document.getElementById("adults").value) + 1}><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24"
                                        viewBox="0 0 24 24" fill="none"
                                        stroke="var(--secondary_color)"
                                        strokeWidth="1"
                                        className="icon icon-tabler icons-tabler-outline icon-tabler-plus"><path
                                        stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 5l0 14"/><path
                                        d="M5 12l14 0"/></svg></button>
                                </span>
                            </div>
                            <div>
                                <label htmlFor={"kids"}>{t("contact:contact.kids")}</label>
                                <span className="input-wrapper">
                                    <button id="decrement-kid" type={"button"}
                                            onClick={() => document.getElementById("kids").value = document.getElementById("kids").value > 0 ? parseInt(document.getElementById("kids").value) - 1 : 0}><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24"
                                        viewBox="0 0 24 24" fill="none"
                                        stroke="var(--secondary_color)"
                                        strokeWidth="1"
                                        className="icon icon-tabler icons-tabler-outline icon-tabler-minus"><path
                                        stroke="none" d="M0 0h24v24H0z" fill="none"/><path
                                        d="M5 12l14 0"/></svg></button>
                                    <input type="number" value={0} min={0} name={"kids"} id="kids"/>
                                    <button
                                        onClick={() => document.getElementById("kids").value = parseInt(document.getElementById("kids").value) + 1}
                                        type={"button"}><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24"
                                        viewBox="0 0 24 24" fill="none"
                                        stroke="var(--secondary_color)"
                                        strokeWidth="1"
                                        className="icon icon-tabler icons-tabler-outline icon-tabler-plus"><path
                                        stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 5l0 14"/><path
                                        d="M5 12l14 0"/></svg></button>
                                </span>
                            </div>
                            <div className={"select-dropdown"}>
                                <label htmlFor={"dog"}>{t("contact:contact.dog")}</label>
                                <select name={"dog"} id={"dog"}>
                                    <option selected disabled hidden></option>
                                    <option value={1}>{t("contact:contact.yes")}</option>
                                    <option value={0}>{t("contact:contact.no")}</option>
                                </select>
                            </div>
                        </section>
                        <section>
                            <label htmlFor={"firstName"}>{t("contact:contact.first_name")}</label>
                            <input name={"firstName"} id={"firstName"} type={"text"}/>
                        </section>
                        <section>
                            <label htmlFor={"lastName"}>{t("contact:contact.last_name")}</label>
                            <input name={"lastName"} id={"lastName"} type={"text"}/>
                        </section>
                        <section>
                            <label htmlFor={"email"}>{t("contact:contact.mail")}*</label>
                            <input name={"email"} id={"email"} type={"email"} required/>
                        </section>
                        <section>
                            <label htmlFor={"tel"}>{t("contact:contact.tel")}{isCallback ? "*" : ""}</label>
                            <input name={"tel"} id={"tel"} type={"tel"} required={isCallback}/>
                        </section>
                        <section className={"col-span-2"}>
                            <label htmlFor={"tel"}>{t("contact:contact.your_message")}</label>
                            <textarea name={"text"} id={"text"}/>
                        </section>
                        <section>
                            <div className={"checkbox"}>
                                <input name={"callback"} id={"callback"} type={"checkbox"} defaultChecked={false}
                                       onChange={(e) => setIsCallback(e.currentTarget.checked)}/>
                                <label htmlFor={"callback"}>{t("contact:contact.callback")}</label>
                            </div>
                            <div className={"checkbox"}>
                                <input name={"copyMessage"} id={"copyMessage"} type={"checkbox"}
                                       defaultChecked={false}/>
                                <label htmlFor={"copyMessage"}>{t("contact:contact.copy_message")}</label>
                            </div>
                            <p className={"contact-text"}
                               style={{margin: "2rem 0 0 0"}}>{t("contact:contact.data_privacy")}</p>
                            <div className={"checkbox"}>
                                <input name={"dataPrivacy"} id={"dataPrivacy"} type={"checkbox"} defaultChecked={false}
                                       required/>
                                <label htmlFor={"dataPrivacy"}>{t("contact:contact.terms")} <a
                                    className={"link"}
                                    href={"/data-privacy"}>{t("contact:contact.data_privacy")}*</a></label>
                            </div>
                            <div style={{margin: "2rem 0"}}>
                                <p className={"contact-text"}
                                   style={{fontWeight: "bold"}}>{t("contact:contact.contact_greeting")}</p>
                                <p className={"helper-text"}>{t("contact:contact.mandatory")}</p>
                                <button className={"button ui"} disabled={sendSuccessful} type={"submit"}>{t("contact:contact.submit")}</button>
                            </div>
                        </section>
                    </form>
                </div>
            </div>
            <div className={'row centered banner-container'}>
                <div className={'six wide computer twelve wide mobile column centered banner-text'}>
                    <h2 style={{color: '#FAFAFA'}}>{t("contact:contact.banner")}</h2>
                    <p style={{color: 'white'}}>
                        Château le Val <br/>
                        110 Route de St. Jouvin.<br/>
                        50700 Brix
                    </p>
                </div>
                <div className={'ten wide computer sixteen wide mobile column centered'}>
                    <div className={'contact-map-container'}>
                        <MapContainer/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation(['contact'])(Contact);
