import React, {useEffect, useState} from "react"
import {withTranslation} from "react-i18next";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import {Button} from 'semantic-ui-react/dist/commonjs'
import PropTypes from "prop-types";
import {
    fetchMissingTextById,
    getLanguage
} from "../../helper/util";
import * as utils from "./utils";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import DropdownItem from "../dropdownMenu/DropdownItem";
import TranslatorModal from "../translatorModal/TranslatorModal";
import {useServerData} from "../../state/serverDataContext";

const TextStoreHeaderEditor = (props) => {

    const {id, modalOpen, onClose, namespace, textToShow} = props;
    let {i18n} = props;
    const [language, setLanguage] = useState(getLanguage(i18n.language));
    const [targetLanguage, setTargetLanguage] = useState({});
    const [isEditState, setIsEditState] = useState(false);
    const [isTranslatorModalOpen, setIsTranslatorModalOpen] = useState(false);
    const [_textToShow, setTextToShow] = useState(textToShow);
    const [text, setText] = useState(textToShow);
    const [activeLanguageOptions, setActiveLanguageOptions] = useState([]);
    const [textStore, setTextStore] = useState(useServerData());

    useEffect(() => {
        utils.initializeTextStore(textStore,id,namespace,setTextStore,setActiveLanguageOptions,setTargetLanguage,i18n);
        fetchMissingTextById(textStore, id, i18n.language).then(text => {
            let newTextStore = {
                ...textStore,
                ...text
            }
            if (text) {
                setTextStore(newTextStore);
                setTextToShow(text[id][i18n.language]);
            }
        });
    }, []);

    useEffect(() => {
        utils.updateLanguage(textStore,id,language.languageCode,setTextToShow);
        setText(textToShow);
    }, [language]);

    return (
        <Modal
            open={modalOpen}
            className={'margin-auto admin-primary'}>
            <Modal.Header className={'margin-auto admin-primary'}>
                {i18n.t("common:common.edit")}
            </Modal.Header>
            <Modal.Content scrolling className={'margin-auto admin-primary'}>
                {
                    isEditState ?
                        [
                            <div className={'margin-auto admin-primary'}>
                                {
                                    activeLanguageOptions.length > 1 ?
                                        <Button className={"button-primary"}
                                                style={{marginBottom: "10px"}}
                                                onClick={() => {
                                                    setIsTranslatorModalOpen(true);
                                                }}>
                                            {i18n.t("edit:translate.translator")}
                                        </Button>
                                        : null
                                }
                                <Form>
                                    <Form.Field>
                                        <label className={"admin-primary"}
                                               style={{marginTop: '10px'}}>
                                            <b>{i18n.t("edit:edit.title")}</b>
                                        </label>
                                        <TextArea
                                            className={"background-primary admin-primary"}
                                            placeholder={i18n.t("edit:edit.title") + "\nmax. 60 characters"}
                                            value={text.title}
                                            onChange={e => utils.changeText(e, 'title', setText)}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label className={"admin-primary"}>
                                            <b>{i18n.t("edit:edit.description")}</b>
                                        </label>
                                        <TextArea
                                            className={"admin-primary admin-border-primary"}
                                            placeholder={i18n.t("edit:edit.description") + "\nmax. 160 characters"}
                                            value={text.description}
                                            onChange={e => utils.changeText(e, 'description', setText)}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label className={"admin-primary"}>
                                            <b>{i18n.t("edit:edit.text")}</b>
                                        </label>
                                        <TextArea
                                            className={"admin-primary admin-border-primary"}
                                            placeholder={i18n.t("edit:edit.text") + "\nmax. 800 characters"}
                                            style={{height: "150px", width: "100%"}}
                                            value={text.content}
                                            onChange={e => utils.changeText(e, 'content', setText)}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Button.Group>
                                            <Button className={"admin-button-primary"}
                                                    onClick={() => {
                                                        utils.handleSave(text, namespace, id, language.languageCode, textStore, setIsEditState, setTextToShow, i18n, onClose)
                                                        //console.log("STORE: ", textStore)
                                                    }}>
                                                {
                                                    typeof textStore[id] === "undefined" || typeof textStore[id][language.languageCode] === "undefined" ?
                                                        i18n.t("common:common.save")
                                                        : i18n.t("common:common.edit")
                                                }
                                            </Button>
                                            <Button className={"admin-button-warn"} onClick={() => {
                                                setIsEditState(false);
                                            }}>{i18n.t("common:common.cancel")}</Button>
                                        </Button.Group>
                                    </Form.Field>
                                </Form>
                            </div>,
                            <TranslatorModal isTranslatorOpen={isTranslatorModalOpen}
                                             setIsTranslatorOpen={setIsTranslatorModalOpen}
                                             setTargetLanguage={setTargetLanguage}
                                             targetLanguage={targetLanguage}
                                             language={language}
                                             textStore={textStore}
                                             activeLanguageOptions={activeLanguageOptions}
                                             id={id}
                                             editType={'header'}
                                             onClose={editText => {
                                                 setText(editText);
                                                 setTextToShow(text);
                                             }}
                            />
                        ]
                        :
                        <div className={'margin-auto admin-primary'}>
                            <Form.Field style={{paddingBottom: "10px"}}>
                                {
                                    activeLanguageOptions.length > 1 ?
                                        <div style={{display: "inline-flex"}}>
                                            <label className={"header"}
                                                   style={{
                                                       margin: "auto",
                                                       marginRight: "5px"
                                                   }}>
                                                <b>{i18n.t("common:common.language")}: </b>
                                            </label>
                                            <p style={{margin: "auto", marginRight: "5px"}}>{language.language}</p>
                                            <DropdownMenu name={"Select Language"}
                                                          selector={true}
                                                          defaultValue={language.language}
                                                          onChange={(selection) => {
                                                              setLanguage(getLanguage(activeLanguageOptions.find(langToSet => langToSet.label === selection).value));
                                                          }}>
                                                {
                                                    activeLanguageOptions.map((language, index) => (
                                                        <DropdownItem key={index}>{language.label}</DropdownItem>
                                                    ))
                                                }
                                            </DropdownMenu>
                                        </div>
                                        : null
                                }
                            </Form.Field>
                            <div id={id}
                                 dangerouslySetInnerHTML={{__html: typeof _textToShow !== "undefined" ? _textToShow.content : null}}/>
                            <p id={"ssr " + id}>
                                <b>Meta-title:</b> {_textToShow.title}
                            </p>
                            <p id={"ssr " + id}>
                                <b>Meta-description:</b> {_textToShow.description}
                            </p>
                            <Button className={'ui button circular admin-button-primary'}
                                    color="orange"
                                    icon="edit outline"
                                    onClick={() => {
                                        setText(_textToShow);
                                        setIsEditState(true);
                                    }}/>
                            <Button floated={"right"}
                                    className={"admin-button-warn"}
                                    onClick={() => onClose("")}>
                                {i18n.t("common:common.cancel")}
                            </Button>
                        </div>
                }
            </Modal.Content>
        </Modal>
    )
}


TextStoreHeaderEditor.propTypes = {
    id: PropTypes.string,
    modalOpen: PropTypes.bool,
    onClose: PropTypes.func,
    textStore: PropTypes.object,
};

TextStoreHeaderEditor.defaultProps = {
    id: Math.random().toString(),
    modalOpen: false,
    textStore: {},
    onClose: () => {
    }
};

export default withTranslation(['common', 'edit'])(TextStoreHeaderEditor)
