import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import * as utils from "./utils";
import {isEntryInDB} from "../../../../server/helper/ssrHelper";
import {getLanguage, languageOptions} from "../../../helper/util";
import {useServerData} from "../../../state/serverDataContext";
import TranslatorModal from "../../translatorModal/TranslatorModal";
import DropdownMenu from "../../dropdownMenu/DropdownMenu/DropdownMenu";
import DropdownItem from "../../dropdownMenu/DropdownItem/DropdownItem";


const TextStoreTextEditor = (props) => {

    const {modalOpen, id, onClose, i18n, namespace, textToShow} = props;
    const [isEditState, setIsEditState] = useState(false);
    const [isTranslatorOpen, setIsTranslatorOpen] = useState(false);
    const [language, setLanguage] = useState(i18n.language);
    const [_textToShow, setTextToShow] = useState(textToShow);
    const [textStore, setTextStore] = useState(useServerData());
    const [targetLanguage, setTargetLanguage] = useState({});
    const [activeLanguageOptions, setActiveLanguageOptions] = useState([]);

    useEffect(() => {
        if (typeof textStore[id] === "undefined") {
            isEntryInDB(id, namespace).then(text => {
                if (Object.keys(text).length !== 0) {
                    setTextStore({...textStore, text});
                }
            });
        }
        setActiveLanguageOptions(languageOptions("nativeLanguage", true));
        setTargetLanguage(getLanguage(i18n.language));
    }, []);

    useEffect(() => {
        if (Object.keys(textStore).length > 0 && typeof textStore[id] !== "undefined" && typeof textStore[id][language.languageCode] !== "undefined") {
            setTextToShow({
                title: textStore[id][language.languageCode].title,
                description: textStore[id][language.languageCode].description,
                content: textStore[id][language.languageCode].content
            });
        } else {
            setTextToShow({title: '', description: '', content: ''});
        }
    }, [language]);

    return (
        <Modal
            open={modalOpen}
            className={'margin-auto admin-primary'}>
            <Modal.Header
                className={"admin-primary"}>{i18n.t("common:common.edit")}</Modal.Header>
            <Modal.Content className={"admin-primary"} scrolling>
                {
                    isEditState ?
                        [
                            <div>
                                <Form>
                                    {
                                        activeLanguageOptions.length > 1 ?
                                            <Button className={"admin-button-basic"}
                                                    style={{marginBottom: "10px"}}
                                                    onClick={() => {
                                                        setIsTranslatorOpen(true)
                                                    }}>
                                                {i18n.t("edit:translate.translator")}
                                            </Button>
                                            : null
                                    }
                                    <Form.Field>
                                        <label className={"admin-primary"}>Edit</label>
                                        <TextArea className={"edit-text-area admin-primary"}
                                                  name='content'
                                                  onChange={(e) => {
                                                      let value = e.target.value;
                                                      setTextToShow(prev => ({...prev, content: value}))
                                                  }}
                                                  value={_textToShow.content}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Button.Group>
                                            <Button className={"admin-button-primary"}
                                                    onClick={() => {
                                                        utils.handleSave(_textToShow, namespace, textStore, id, language, setIsEditState, onClose);
                                                    }}>
                                                {
                                                    typeof textStore[id] === "undefined" || typeof textStore[id][language.languageCode] === "undefined" ?
                                                        i18n.t("common:common.save")
                                                        : i18n.t("common:common.edit")
                                                }
                                            </Button>
                                            <Button className={"admin-button-warn"}
                                                    onClick={() => {
                                                        setIsEditState(false);
                                                    }}>{i18n.t("common:common.cancel")}</Button>
                                        </Button.Group>
                                    </Form.Field>
                                </Form>
                            </div>,
                            <TranslatorModal
                                isTranslatorOpen={isTranslatorOpen}
                                setIsTranslatorOpen={setIsTranslatorOpen}
                                setTargetLanguage={setTargetLanguage}
                                targetLanguage={targetLanguage}
                                language={language}
                                textStore={textStore}
                                activeLanguageOptions={activeLanguageOptions}
                                id={id}
                                editType={'text'}
                                setIsEditState={setIsEditState}
                                onClose={(text) => {
                                    setTextToShow({...textStore, content: text});
                                }}
                            />
                        ]
                        : <div>
                            {
                                activeLanguageOptions.length >= 1 ?
                                    <div style={{display: "inline-flex"}}>
                                        <label
                                            style={{
                                                margin: "auto",
                                                marginRight: "5px"
                                            }}>
                                            <b>{i18n.t("common:common.language")}: </b>
                                        </label>
                                        <p style={{margin: "auto", marginRight: "5px"}}>{language.label}</p>
                                        <DropdownMenu name={"Select Language"}
                                                      selector={true}
                                                      defaultValue={language.label}
                                                      onChange={(selection) => {
                                                          setLanguage(getLanguage(activeLanguageOptions.find(langToSet => langToSet.label === selection).value));
                                                      }}>
                                            {
                                                activeLanguageOptions.map((language, index) => (
                                                    <DropdownItem key={index}>{language.label}</DropdownItem>
                                                ))
                                            }
                                        </DropdownMenu>
                                        <Divider/>
                                    </div> : null
                            }
                            <div style={{marginTop: '10px', marginBottom: '10px'}}
                                 id={id}
                                 dangerouslySetInnerHTML={{__html: typeof _textToShow !== "undefined" ? _textToShow.content : null}}/>
                            <Button className={'ui button circular admin-button-primary'}
                                    disabled={typeof language === "string"}
                                    icon="edit outline"
                                    onClick={() => {
                                        setIsEditState(true);
                                    }}/>
                            <Button floated="right"
                                    className={"admin-button-warn"}
                                    onClick={() => {
                                        onClose("");
                                    }}
                            >{i18n.t("common:common.cancel")}</Button>
                        </div>
                }
            </Modal.Content>
        </Modal>
    )

}

TextStoreTextEditor.propTypes =
    {
        id: PropTypes.string,
        modalOpen: PropTypes.bool,
        onClose: PropTypes.func,
        namespace: PropTypes.string
    };

TextStoreTextEditor.defaultProps =
    {
        id: Math.random().toString(),
        modalOpen: false,
        namespace: "",
        onClose: () => {
        }
    };

export default withTranslation(['edit', 'common'])(TextStoreTextEditor);