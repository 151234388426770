import React, {useEffect, useState} from 'react';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import {useCookies} from 'react-cookie';
import i18n from "i18next";

const MAX_MOBILE_WIDTH = 600;

const mapStyle = [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#ffffff'
            }
        ]
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#000000'
            },
            {
                lightness: 13
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#000000'
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#231f20'
            },
            {
                lightness: 14
            },
            {
                weight: 1.4
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#231f20'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#9fc058'
            },
            {
                lightness: 5
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#9fc058'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#231f20'
            },
            {
                lightness: 25
            },
            {
                weight: '1.65'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fafafa'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#231f20'
            },
            {
                lightness: 16
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#fafafa'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#9fc058'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                color: '#9fc058'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#9fc058'
            }
        ]
    }
];

let containerStyle = {
    width: '98%',
    height: '800px'
};

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

function _mapLoaded(mapProps, map) {
    map.setOptions({
        styles: mapStyle
    });
}

const MapContainer = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const containerWidth = useWindowSize().width;
    if (containerWidth <= MAX_MOBILE_WIDTH) {
        containerStyle = {
            width: '95%',
            height: '400px'
        };
    } else {
        containerStyle = {
            width: '98%',
            height: '800px'
        };
    }

    if (cookies.consentGiven && cookies.consentGiven.includes('performance'))
        return (
            <Map
                google={props.google}
                zoom={17}
                style={containerStyle}
                initialCenter={{
                    lat: 49.54560645710591,
                    lng: -1.6004057848734212
                }}
                onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
            >
                <Marker
                    title={'110 Route de St. Jouvin'}
                    name={'Château le Val'}
                    onClick={() =>
                        window.open(
                            'https://www.google.com/maps/place/Ch%C3%A2teau+Val/@49.5455183,-1.6024561,17z/data=!3m1!4b1!4m8!3m7!1s0x480c9a9d04a9b7b5:0xe8592dda97a0e620!5m2!4m1!1i2!8m2!3d49.5455163!4d-1.6002672',
                            '_blank'
                        )
                    }
                    position={{lat: 49.54560645710591, lng: -1.6004057848734212}}
                    icon={''}
                />
            </Map>
        );
    else
        return (
            <div style={{color: 'white', textAlign: 'center', paddingTop: '10%'}}>
                <p>{i18n.t("contact:contact.cookie_info")}</p>
                <button
                    className="ui grey button"
                    onClick={() => {
                        Object.keys(cookies).map(cookie => {
                            removeCookie(cookie);
                        });
                    }}
                >
                    {i18n.t("contact:contact.cookie_info")}
                </button>
            </div>
        );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAThy-xy11vgdLXPNLu0C4ej8Z-AjdGVhg'
})(MapContainer);
