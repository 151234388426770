import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import {Label} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

import {fetchMissingTextById} from "../../helper/util";
import userStore from "../adminComponents/userManagement/userStore";
import {getCMSItemById} from "../../helper/cmsHelper";
import {useLocation} from "react-router-dom";
import {useServerData} from "../../state/serverDataContext";
import TextStoreHeaderEditor from "./TextStoreHeaderEditor";

/*
const TextStoreHeaderEditor = loadable({
    loader: () => import(/* webpackChunkName: 'TextStoreHeaderEditor' / "./TextStoreHeaderEditor"),
    loading: () => LoaderComponent
});
*/

const EditHeaderComponent = (props) => {
    const {id, namespace, type, primary, secondary} = props;
    let {i18n} = props;
    const [textStore, setTextStore] = useState(useServerData());
    const [modalOpen, setModalOpen] = useState(false);
    const [textToShow, setTextToShow] = useState(getCMSItemById(textStore, id, i18n.language));
    const [canEdit, setCanEdit] = useState(userStore.isAdmin || userStore.isContentCreator);
    const search = useLocation().search;


    useEffect(() => {
        fetchMissingTextById(textStore, id, i18n.language).then(text => {
            let newTextStore = {
                ...textStore,
                ...text
            }
            if (text) {
                setTextStore(newTextStore);
                setTextToShow(text[id][i18n.language]);
            }
        });
    }, []);

    useEffect(() => {
        setCanEdit((userStore.isAdmin || userStore.isContentCreator) && search !== "?preview");
    }, [userStore.isAdmin, userStore.isContentCreator, search]);

    if (canEdit && typeof document !== "undefined") {
        return (
            <>
                <Button className={"circular edit_button admin-button-primary"}
                        style={{top: '10px'}}
                        icon={"edit outline"}
                        onClick={() => {
                            setModalOpen(true);
                        }}/>
                <TextStoreHeaderEditor id={id}
                                       modalOpen={modalOpen}
                                       textStore={textStore}
                                       textToShow={textToShow}
                                       namespace={namespace}
                                       onClose={(text) => {
                                           if (text !== "") {
                                               setTextToShow({
                                                   title: text.title,
                                                   description: text.description,
                                                   content: text.content,
                                                   isNamespace: namespace
                                               });
                                           }
                                           setModalOpen(false);
                                       }}
                />
                <>
                    <Helmet>
                        <title>{typeof textToShow !== "undefined" ? textToShow.title : null}</title>
                        <meta name="description"
                              content={typeof textToShow !== "undefined" ? textToShow.description : null}/>
                        <meta name="title" content={typeof textToShow !== "undefined" ? textToShow.title : 'hallo'}/>
                        <meta name="robots" content="index,follow"/>
                    </Helmet>
                    <>
                        {
                            textToShow ?
                                type === 'h1' ?
                                    <h1 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h1>
                                    : type === 'h2' ?
                                    <h2 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h2>
                                    : type === 'h3' ?
                                        <h3 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h3>
                                        : type === 'h4' ?
                                            <h4 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h4>
                                            : type === 'h5' ?
                                                <h5 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h5>
                                                : type === 'h6' ?
                                                    <h6 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h6>
                                                    :
                                                    <h1 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h1>
                                : null
                        }
                        {
                            textToShow.isNamespace === namespace ? ""
                                : <Label size={"tiny"}
                                         className={"namespace-note"}
                                         style={{opacity: "80%"}}
                                         floating
                                         color={"red"}>
                                    !
                                </Label>
                        }
                    </>
                </>
            </>
        )
    } else {
        return (
            <>
                <Helmet>
                    <title>{typeof textToShow !== "undefined" ? textToShow.title : null}</title>
                    <meta name="description"
                          content={typeof textToShow !== "undefined" ? textToShow.description : null}/>
                    <meta name="title" content={typeof textToShow !== "undefined" ? textToShow.title : null}/>
                    <meta name="robots" content="index,follow"/>
                </Helmet>
                {
                    textToShow ?
                        type === 'h1' ?
                            <h1 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h1>
                            : type === 'h2' ?
                            <h2 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h2>
                            : type === 'h3' ?
                                <h3 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h3>
                                : type === 'h4' ?
                                    <h4 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h4>
                                    : type === 'h5' ?
                                        <h5 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h5>
                                        : type === 'h6' ?
                                            <h6 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h6>
                                            :
                                            <h1 className={primary ? "header-primary" : secondary ? "header-secondary" : ""}>{textToShow.content}</h1>
                        : null
                }
            </>
        )
    }
}

EditHeaderComponent.propTypes = {
    id: PropTypes.string,
    textStore: PropTypes.object,
    namespace: PropTypes.string
};

EditHeaderComponent.defaultProps = {
    id: Math.random().toString(),
    namespace: "",
    textStore: {},
};

export default withTranslation(['common'])(EditHeaderComponent)
